import moment from "moment";
import api from "../api";
import loginService from "../login.service";

export const findAll = async (filters, pagination) =>
    api.post({
        type: "user",
        service: `marketing/vouchers/filter`,
        data: filters,
        params: pagination,
    });

export const find = async (id) =>
    api.get({ type: "user", service: `marketing/vouchers/${id}` });

export const findCustomerVoucherByCode = async (code) =>
    api.get({ type: "user", service: `marketing/vouchers/code/${code}` });

export const findUser = async (voucherId, filter, pagination) =>
    api.post({
        type: "user",
        service: `marketing/vouchers/user/${voucherId}`,
        data: filter,
        params: pagination,
    });

export const mapExportColumns = (vouchers) =>
    vouchers.map((voucher) => ({
        id: voucher.id,
        Código: voucher.code,
        Desconto: voucher.discount,
        "Criado em": moment(voucher.created).format("DD/MM/YYYY"),
        Expirado: voucher.isActive ? "Não" : "Sim",
        "Expira em": voucher.expirationDateTime ? moment(voucher.expirationDateTime).format("DD/MM/YYYY HH:mm") : "-",
        "Uso total": voucher.usage,
        Recorrência: voucher.recurrence,
        Produto: voucher.productName
    }));

export const mapExportUserColumns = (userVouchers) =>
    userVouchers.map((voucher) => ({
        id: voucher.voucherId,
        "Usado em": voucher.voucherCreatedAt,
        "ID do cliente": voucher.customerId,
        "Nome do cliente": voucher.customerName,
        Email: voucher.customerEmail,
        Telefone: voucher.customerPhone,
    }));

export const checkAvailability = async (data) =>
    api.post({
        type: "user",
        service: `marketing/vouchers/check-availability`,
        data,
    });

export const save = async (data) =>
    api.post({ type: "user", service: `marketing/vouchers/`, data });

export const edit = async (voucher) =>
    api.put({
        type: "user",
        service: `marketing/vouchers/${voucher.id}`,
        data: mapValues(voucher),
    });

export const deactivateVoucher = async (voucherId) =>
    api.remove({ type: 'user', service: `marketing/vouchers/${voucherId}/deactivate` })

export const obtainCustomerRepurchaseVoucher = async (customerId, additionalInformation) =>
    api.post({
        type: 'user',
        service: `marketing/vouchers/customer-repurchase/${customerId}`,
        data: additionalInformation,
    })

export const changeFieldsInList = async (vouchers, id, fields) => {
    const copy = [...vouchers];

    const { data } = await find(id);

    const index = copy.findIndex((x) => x.id == data.id);

    for (const field in fields) {
        copy[index][field] = data[field];
    }

    return [...copy];
};

export const mapValues = (data) => ({
    expirationDateTime: data.expirationDateTime,
    code: data.code,
    discount: data.discount,
    discountType: data.discountType,
    recurrence: data.recurrence,
    description: data.description,
    packageIds: data.packageIds,
    isSingle: data.isSingle,
    daysOfWeek: data.daysOfWeek,
});

export const newValue = () => ({
    expirationDateTime: moment().add(5, "days").format("YYYY-MM-DDT23:00"),
    code: "",
    discount: null,
    discountType: "percentage",
    recurrence: 1,
    description: "",
    packageIds: [],
    isSingle: false,
    userId: loginService.getUserAuthentication().id,
    daysOfWeek: [0, 0, 0, 0, 0, 0, 0]
});
