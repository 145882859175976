import { useTheme } from "@material-ui/core";
import styled, { css } from "styled-components";
import LoadingSpin from "../LoadingSpin";

const variantStyles = {
    primary: css`
        background-color: ${props => props.primaryColor};
        color: #fff;
        transition: filter .3s ease;
        &:hover {
            filter: brightness(1.2);
        }
    `,
    secondary: css`
        background-color: #fff;
        color: #232323;
        border: solid 1px #dedede;
        transition: color .3s ease, border-color .3s ease, background-color .3s ease;
        &:hover {
            background-color: #fafafa;
            color: ${props => props.primaryColor};
            border-color: ${props => props.primaryColor};
        }
    `,
    dashed: css`
        background-color: #fff;
        color: #232323;
        border: dashed 1px ${props => props.primaryColor};
        transition: color .3s ease, border-color .3s ease, background-color .3s ease;
        &:hover {
            background-color: #fafafa;
            color: ${props => props.primaryColor};
            border-color: ${props => props.primaryColor};
        }
    `,
    text: css`
        background-color: transparent;
        color: #232323;
        transition: background-color .3s ease;
        &:hover {
            background-color: #f0f0f0;
        }
    `,
    link: css`
        background-color: transparent;
        color: ${props => props.primaryColor};
        transition: filter .3s ease;
        &:hover {
            filter: brightness(1.2);
        }
    `
};

const StyledButton = styled.button`
    padding: 6px 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => variantStyles[props.variant || 'primary']}
`;

export default function Button({
    onClick,
    children,
    type = 'primary',
    icon = null,
    loading = false,
    ...props
}) {

    const theme = useTheme();

    return (
        <StyledButton
            type="button"
            onClick={!loading ? onClick : undefined}
            primaryColor={theme.palette.primary.main}
            variant={type}
            {...props}
        >
            <div
                className="h-full overflow-hidden flex items-center justify-start"
                style={{
                    transition: 'width .3s ease',
                    width: icon || loading ? 22 : 0,
                }}
            >
                <span className="w-[14px] h-[14px] flex items-center justify-center">
                    {loading ? <LoadingSpin size="small" color={type === 'primary' ? '#fff' : theme.palette.primary.main} /> : icon}
                </span>
            </div>
            {children}
        </StyledButton>
    )
}