import React, { useEffect, useRef, useState } from 'react';
import { animateScroll } from 'react-scroll';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';

import EditFieldsModal from '../EditFieldsModal';
import SkeletonLoading from '../SkeletonLoading';
import swal from 'sweetalert2';

import requestFieldConfig from './fields/request.fields.config';
import loginService from '../../domain/services/login.service';
import RequestHearingAdditionalModal from './request.hearing.additional.modal';
import RequestAdditionalChargeByProcessDocument from './request.additional.charge.by.process.document';
import documentsService from "../../domain/services/solicitation/documents.service";
import { requestServices } from '../../domain/constant/request.services.constant';


import datetimeProvider from '../../providers/datetime.provider';

import requestAdvanceService from '../../domain/services/request/advance.service';
import { servicesByTypeKey } from "../../domain/constant/request.services.constant";
import modalities from '../../domain/constant/modalities.constant';

import ReactPixel from 'react-facebook-pixel';
import { requestRegistrationPixelID, options } from '../../domain/constant/pixel.constant';
import { useTour } from '../../context/tourContext';
import { tours } from '../../domain/constant/tour.constant';

const RequestForm = (props) => {

    ReactPixel.init(requestRegistrationPixelID, {}, options);

    const {
        request,
        disabled,
        isSmall,
        isEdit,
        setRequest,
        discardChanges,
        onConfirm,
        setAlert,
        warningHearingAntecipation,
    } = props;

    const location = useLocation();
    const history = useHistory();

    const { registerRef } = useTour();

    const refs = {
        formContainerRef: registerRef(tours.howToEditTour, 'formContainer'),
        servicesRef: registerRef(tours.howToEditTour, 'services'),
        saveRef: registerRef(tours.howToEditTour, 'save'),
    };

    const { showHearingAnalysisModal: showInitalModal } = location.state || {};

    const [dynamicFields, setDynamicFields] = useState({});
    const [editModal, setEditModal] = useState({});

    const [fieldsWithError, setFieldsWithError] = useState([]);
    const [isFieldsLoading, setIsFieldsLoading] = useState(true);

    const [isFormCompleted, setIsFormCompleted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [refreshDocuments, setRefreshDocuments] = useState(true);


    const [showHearingAnalysisModal, setShowHearingAnalysisModal] = useState(!!showInitalModal);
    const [showRequestAdditionalChargeByProcessDocumentModal, setShowRequestAdditionalChargeByProcessDocumentModal] = useState(false);

    const requestFormSteps = requestFieldConfig.getRequestFieldsSteps(request, !isEdit, isFormCompleted);

    const hearingAnalysisModalCallback = useRef(null);

    const checkHearingMinDate = (name, request) => {

        const hearingServices = servicesByTypeKey.hearing.services;
        const isHearing = hearingServices.includes(request.mainServiceKey) || hearingServices.includes(request.serviceKey) || [requestServices.structure.hearingInPerson.id, requestServices.structure.hearing.id].includes(request.mainServiceId);

        if (name !== 'processInformation' || !isHearing)
            return {};

        const serviceKeyStrategies = {
            default: () => datetimeProvider.addBusinessDays(2),
            [requestServices.structure.hearingInPerson.id]: () => loginService.isInternal() ? datetimeProvider.addBusinessDays(1) : datetimeProvider.addBusinessDays(3),
            [requestServices.structure.hearing.id]: () => loginService.isInternal() ? datetimeProvider.addBusinessDays(1) : datetimeProvider.addBusinessDays(2),
        };

        const mainServiceId = request.mainServiceId || request.serviceId;

        const minDate = (serviceKeyStrategies[mainServiceId] || serviceKeyStrategies['default'])();

        return {
            minDate: minDate,
            warningHearingAntecipation
        };
    };

    const checkMinDate = (name = null) =>
        checkHearingMinDate(name, request);

    const fieldProps = (name = null) => ({
        ...checkMinDate(name),
        titleColor: '#000000DE',
        titleSize: 15,
        titleWeight: 500,
        spacing: 10,
        disabled: disabled || isFormCompleted || isSubmitting
    })

    const cleanUpLocationState = () => {
        history.replace({
            pathname: location.pathname,
            state: null,
        });
    }


    const getDocuments = async () => {
        setLoading(true);
        const { data } = await documentsService.find(request.id);
        setDocuments(data || []);
        setLoading(false);
        return data;
    }

    useEffect(() => getFields(), [request.areaId, request.modalityId]);

    useEffect(() => cleanUpLocationState(), []);


    const getFields = async () =>
        requestFieldConfig
            .getFields(request.areaId, request.modalityId, request, isEdit)
            .then(fields => {
                setDynamicFields(requestFieldConfig.checkToDisableFields(fields, request));
                setFieldsWithError([]);
            })
            .finally(() => setIsFieldsLoading(false));

    const handleAnalysisModalSelection = () => {
        hearingAnalysisModalCallback.current()
            .then(() => {
                setShowHearingAnalysisModal(false);
            })
    }

    const handleProcessDocumentChargeConfirm = () => {
        setIsSubmitting(true);
        submitRequest();
    }

    const hasMandatoryDocumentsNotAnexed = (currentDocuments) => {

        const mandatoryDocumentsList = documentsService.uploadListByAreaId(request.areaId)
            .filter(item => item.required && (item.modalityIds || []).includes(request.modalityId))
            .map((item) => { return item.nameType })

        if (!mandatoryDocumentsList.length)
            return false

        const allDocumentsFound = mandatoryDocumentsList.every(mandatoryDoc => currentDocuments.some(doc => doc.nameType === mandatoryDoc));

        return !allDocumentsFound;
    }

    const handleConfirm = async () => {

        setIsSubmitting(true);

        const currentDocuments = await getDocuments();

        if (hasMandatoryDocumentsNotAnexed(currentDocuments))
            return onMandatoryDocumentsError()

        const hasProcessDocument = currentDocuments.find(doc => doc.isProcessMainDocument);

        if (hasProcessDocument || (!modalities[request.modalityKey]?.processDocumentRequired && !request.processNumber) || isEdit)
            return submitRequest();

        setIsSubmitting(false);

        return setShowRequestAdditionalChargeByProcessDocumentModal(true);
    }

    const onMandatoryDocumentsError = (err) => {

        setIsSubmitting(false);

        swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">Documentos não anexados</p>`,
            html: `
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">Os documentos obrigatórios da solicitação não foram enviados.</p>
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">Por favor, anexe os documentos que estão pendentes para finalizar o cadastro.</p>
            `,
            icon: 'error',
            confirmButtonText: 'Anexar documentos',
            confirmButtonColor: "#366FD0",
        });
        return;

    }

    const onAdvanceCancelError = (err) => {

        const resData = err?.response?.data || {}

        if (resData.canSkipValidation)
            return;

        swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">Não foi possível cancelar a antecipação da data de entrega!</p>`,
            html: `
                ${resData.message ? ' <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;"><b>Motivo:</b> ' + resData.message : ''}
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">Contate o suporte ou tente novamente mais tarde!</p>
            `,
            icon: 'error',
            confirmButtonText: 'Fechar'
        });
        return;

    }

    const submitRequest = async () => {

        await onConfirm()
            .then(async () => {

                if (isEdit) {

                    if ([servicesByTypeKey.hearing.id].includes(request.serviceTypeId) && datetimeProvider.getBusinessDatesCount(request.hearingDateTime) >= 5 && request.isDueDateAdvanced) {
                        await requestAdvanceService.cancelAdvance(request.mainId || request.id, false)
                            .then(() => {
                                return;
                            }).catch(onAdvanceCancelError);
                    }

                    return;
                }

                ReactPixel.trackCustom('FinishedRequestRegistration', {});
                setIsFormCompleted(true);
                animateScroll.scrollToBottom();

            })
            .catch(err => null)
            .finally(() => setIsSubmitting(false));
    }

    const handleOpenEditModal = (initialValues, callback) =>
        setEditModal({ open: true, initialValues, callback });

    const checkIfHasNotInPersonHearing = ![request.serviceId, request.mainServiceId].includes(requestServices.structure.hearingInPerson.id)

    const exclusiveVirtualHearingFields = ['hearingApp', 'hearingLink'];

    const showVirtualHearingFields = (fieldKey) => !exclusiveVirtualHearingFields.includes(fieldKey)

    const dynamicFieldsToRender = Object.entries(dynamicFields).reduce((allFields, [key, fields]) => {

        let fieldsToFilter = [...fields];

        if (key === 'factsAndFundamentals') fieldsToFilter = fieldsToFilter.filter(field => checkIfHasNotInPersonHearing ? true : showVirtualHearingFields(field.key))

        const fieldsWithoutExcludedActions = fieldsToFilter.filter(field => !requestFieldConfig.isExcludedActionType(request, field?.validation?.excludedActionTypes));

        return ({
            ...allFields,
            [key]: fieldsWithoutExcludedActions
        });
    }, {});

    const getContent = (item) => {

        if (isFieldsLoading)
            return (
                <Grid item xs={12}>
                    <SkeletonLoading size={{ height: 130 }} isDataLoading variant='rect' />
                </Grid>
            );

        const isValid = !item.validator || item.validator(request);

        if (!isValid)
            return 'Selecione um serviço e, se necessário, escolha uma modalidade para visualizar e preencher os campos.';

        return item.Component && (
            <item.Component
                {...props}
                refreshDocuments={refreshDocuments}
                stepFields={dynamicFieldsToRender[item.name] || []}
                fieldsWithError={fieldsWithError}
                fieldProps={fieldProps(item.name)}
                openEditModal={handleOpenEditModal}
                hearingAnalysisModalCallback={hearingAnalysisModalCallback}
                isEdit={isEdit}
            />
        );
    }

    const getSubmmitButtonLabel = () => {

        if (!isSubmitting)
            return isEdit ? 'Salvar' : 'Finalizar Cadastro';

        return (
            <>
                Carregando...
                <CircularProgress size={16} style={{ marginLeft: 10 }} />
            </>
        )
    }

    const closeAdditionalChargeModal = (isCancelled = false) => {
        setRefreshDocuments(!refreshDocuments);
        setShowRequestAdditionalChargeByProcessDocumentModal(false)
        if (!isCancelled) return;
        return document.getElementById('processNumber').scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const customValidation = (fieldGroup) => {

        const customFieldValidation = {
            premiumServices: () => !!request?.fields?.linkPremiumFaciliter,
        };

        const validationAction = customFieldValidation[fieldGroup.name];

        if (!validationAction) return false;

        return validationAction();
    }

    return (
        <>

            <RequestAdditionalChargeByProcessDocument
                open={showRequestAdditionalChargeByProcessDocumentModal}
                onConfirm={handleProcessDocumentChargeConfirm}
                request={request}
                isEdit={isEdit}
                setOpen={setShowRequestAdditionalChargeByProcessDocumentModal}
                documents={documents}
                loading={loading || isSubmitting}
                getDocuments={getDocuments}
                setLoading={setLoading}
                setAlert={setAlert}
                onClose={closeAdditionalChargeModal}
                setRequest={setRequest}
            />


            {showHearingAnalysisModal && (
                <RequestHearingAdditionalModal
                    open={showHearingAnalysisModal}
                    onFinish={handleAnalysisModalSelection}
                    onClose={() => setShowHearingAnalysisModal(false)}
                />
            )}

            <div ref={refs.formContainerRef}>
                <Grid container direction='column' style={{ gap: isSmall ? 35 : 50 }}>

                    {requestFormSteps
                        .filter(item => !request.referenceAnalysisId || item.name != "processInformation")
                        .filter(item => !item.needFieldsToRender || (dynamicFieldsToRender[item.name] || []).length > 0 || customValidation(item))
                        .map((item, index) => (
                            <React.Fragment key={index}>

                                {!!index && (
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                )}

                                {item.fullWidth ? getContent(item) : (
                                    <Grid
                                        item spacing={isSmall ? 6 : 0}
                                        direction={isSmall ? 'column' : 'row'}
                                        justifyContent='space-between' container
                                    >

                                        <Grid item xs={isSmall ? 12 : 4}>

                                            <Typography variant='h6' gutterBottom>
                                                <strong>{item.label(request)}</strong>
                                            </Typography>

                                            <Typography variant='caption' color='textSecondary'>
                                                {item.description && item.description(request)}
                                            </Typography>

                                            {item.extra && (
                                                <div>
                                                    {item.extra()}
                                                </div>
                                            )}

                                            {item.htmlDescription && (
                                                <div dangerouslySetInnerHTML={{ __html: item.htmlDescription(request) }} />
                                            )}

                                        </Grid>

                                        <Grid item xs={isSmall ? 12 : 7} container>
                                            <Grid xs={12}>
                                                <div
                                                    ref={item.name === 'mainInformation' ? refs.servicesRef : null}
                                                    className='p-8 md:p-10 rounded-lg bg-[#fff] border border-solid border-[#ededed] shadow-[0px_4px_4px_#0001]'
                                                >
                                                    {getContent(item)}
                                                </div>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                )}

                            </React.Fragment>
                        ))
                    }

                    {
                        (isEdit || !isFormCompleted) && !loginService.isFaciliter() && (
                            <Grid
                                item container justifyContent='flex-end'
                                style={{ marginTop: 20 }}
                            >
                                <Grid
                                    item container direction='row-reverse'
                                    xs={isSmall ? 12 : 7}
                                >

                                    <div className='flex flex-col md:flex-row md:justify-end gap-3 w-full'>

                                        {isEdit && (
                                            <Button
                                                className='w-full md:w-auto'
                                                variant='outlined'
                                                size='large'
                                                onClick={discardChanges}
                                                disabled={fieldProps().disabled}
                                            >
                                                Cancelar
                                            </Button>
                                        )}

                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='large'
                                            className='font-semibold w-full md:w-72'
                                            onClick={handleConfirm}
                                            disabled={fieldProps().disabled}
                                            ref={refs.saveRef}
                                        >
                                            {getSubmmitButtonLabel()}
                                        </Button>
                                    </div>

                                </Grid>
                            </Grid>

                        )
                    }
                </Grid >
            </div >

            <EditFieldsModal
                {...props}
                open={editModal.open}
                initialValues={editModal.initialValues}
                onSave={editModal.callback}
                onClose={() => setEditModal({})}
            />

        </>
    );
}

export default RequestForm;