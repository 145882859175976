import moment from "moment";

const formatDateTime = (datetime, format = 'DD/MM/YYYY HH:mm', useLocalTime = false) =>
    moment(datetime).utc(useLocalTime).format(format)

const getTimeDifference = (unit, datetime1, datetime2) =>
    moment(datetime2).utc().diff(moment(datetime1).utc(), unit);

const getDate = (date) => moment(date);

const momentInstance = () => moment;

const isSameDate = (initDate, finalDate, unit) => {

    if (!initDate || !finalDate)
        return false;

    return moment(moment(initDate).format('YYYY-MM-DD')).isSame(moment(finalDate).format('YYYY-MM-DD'), unit);
}

const getTimeDifferenceUntilDate = (initialDate, finalDate, defaultText = "Solicitação realizada") => {

    const initial = moment(initialDate);
    const final = moment(finalDate);

    const diff = final.diff(initial);

    if (diff < 0) {
        return defaultText;
    }

    const duration = moment.duration(diff);

    let timeKey = 'belowAHour';
    timeKey = duration.asHours() >= 1 ? 'overAHour' : timeKey;
    timeKey = duration.asDays() >= 1 ? 'overADay' : timeKey;

    const timeTexts = {
        overADay: time => "Em " + Math.floor(time.asDays()) + " dias",
        overAHour: time => "Em " + Math.floor(time.asHours()) + " horas e " + time.minutes() + " minutos",
        belowAHour: time => "Em " + time.minutes() + " minutos",
    };

    return timeTexts[timeKey](duration);
}

const getBusinessDatesCount = (date, dateStart = null) => {
    let count = 0;
    const initialDate = dateStart ? new Date(dateStart) : new Date();
    // console.log(initialDate)
    initialDate.setHours(0, 0, 0, 0);
    const endDate = new Date(date);
    endDate.setHours(0, 0, 0, 0);

    while (initialDate < endDate) {
        const dayOfWeek = initialDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        initialDate.setDate(initialDate.getDate() + 1);
    }
    return count;
};

const addBusinessDays = (days) => {
    const date = new Date();
    let addedDays = 0;

    while (addedDays < days) {
        date.setDate(date.getDate() + 1);
        const dayOfWeek = date.getDay();

        if (dayOfWeek !== 0 && dayOfWeek !== 6)
            addedDays++;
    }

    return date;
};

const getTomorrowDate = () => {

    const today = new Date();
    const tomorrow = new Date(today);

    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
};

const formatDurationFromMinutes = (durationInMinutes = 0, minUnit = 'minutes') => {

    const formattedDurationParts = [];
    const dateFormatSteps = getDateFormatSteps(minUnit);
    let currentDuration = durationInMinutes;

    for (const step of dateFormatSteps) {

        const rawValue = currentDuration / step.denominatorToUnitValue;
        const value = step.unit === minUnit ? Math.ceil(rawValue) : Math.floor(rawValue);

        if (value <= 0)
            continue;

        currentDuration = currentDuration % step.denominatorToUnitValue;
        formattedDurationParts.push(step.getLabel(value));
    }

    return formattedDurationParts.reduce((acc, curr, index) => {

        if (formattedDurationParts.length === 1)
            return curr;

        if (index === formattedDurationParts.length - 1)
            return `${acc} e ${curr}`;

        return `${acc}${acc ? ', ' : ''}${curr}`;

    }, '');
};

const getDateFormatSteps = (minUnit) => {

    const minUnitStepIndex = dateFormatSteps.findIndex(step => step.unit === minUnit);
    if (minUnitStepIndex === -1)
        return dateFormatSteps;

    return dateFormatSteps.slice(0, minUnitStepIndex + 1);
}

const dateFormatSteps = [
    {
        unit: 'years',
        denominatorToUnitValue: 525600,
        getLabel: (years) => `${years} ano${years > 1 ? 's' : ''}`,
    },
    {
        unit: 'months',
        denominatorToUnitValue: 43200,
        getLabel: (months) => `${months} ${months > 1 ? 'meses' : 'mês'}`,
    },
    {
        unit: 'days',
        denominatorToUnitValue: 1440,
        getLabel: (days) => `${days} dia${days > 1 ? 's' : ''}`,
    },
    {
        unit: 'hours',
        denominatorToUnitValue: 60,
        getLabel: (hours) => `${hours} hora${hours > 1 ? 's' : ''}`,
    },
    {
        unit: 'minutes',
        denominatorToUnitValue: 1,
        getLabel: (minutes) => `${minutes} minuto${minutes > 1 ? 's' : ''}`
    }
];

export default {
    formatDurationFromMinutes,
    formatDateTime,
    getTimeDifference,
    isSameDate,
    getTimeDifferenceUntilDate,
    getDate,
    getBusinessDatesCount,
    momentInstance,
    addBusinessDays,
    getTomorrowDate
};