export default function Description({ title, children }) {
    return (
        <div className="flex gap-2">
            <div className="opacity-60">
                {title}:
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}