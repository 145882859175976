import { Tooltip } from "@material-ui/core";
import { useState, useRef } from "react";

const DateTimeTooltip = ({ children }) => {
    const [show, setShow] = useState(false);
    const tooltipRef = useRef();

    const handleMouseOver = (event) => {
        if (tooltipRef && tooltipRef?.current && tooltipRef?.current?.contains(event.target)) {
            setShow(true);
        }
    };

    const handleMouseLeave = (event) => {
        if (tooltipRef && tooltipRef?.current && !tooltipRef?.current?.contains(event.relatedTarget)) {
            setShow(false);
        }
    };

    const handleClick = () => {
        setShow(false);
    };

    return (
        <div
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
        >
            <Tooltip
                PopperProps={{ style: { zIndex: 0 } }}
                open={show}
                title="Insira no horário de Brasília"
            >
                <div ref={tooltipRef}>
                    {children}
                </div>
            </Tooltip>
        </div>
    );
};

export default DateTimeTooltip;
